<template>
    <div>
      <b-modal :id="modalId" title="Select Spells" size="xxl" scrollable>
        <!-- Modal content -->
        <div class="row">
          <div class="col-sm-12 mb-3">
            <b-form-select v-model="selectedSchool" :options="schoolOptions">
              <template #first>
                <b-form-select-option :value="null" disabled>Please select an option</b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <div class="col-sm-12 mb-3">
            <h4>School Spells</h4>
          </div>

          <div class="col-sm-4 mb-3">
            <select class="form-control" name="schoolSpell1" id="schoolSpell1" v-model="schoolSpell1">
              <option value="none">Select a spell</option>
              <option v-for="spell in schoolSpells" :value="spell.name" :key="spell.name" :disabled="inSpellbookNames.includes(spell.name)">{{ spell.name }} ({{ spell.targetNumber }}/{{ spell.targetNumber + 2 }})</option>

            </select>
          </div>

          <div class="col-sm-4 mb-3">
            <select class="form-control" name="schoolSpell2" id="schoolSpell2" v-model="schoolSpell2">
              <option value="none">Select a spell</option>
              <option v-for="spell in schoolSpells" :value="spell.name" :key="spell.name" :disabled="inSpellbookNames.includes(spell.name)">{{ spell.name }} ({{ spell.targetNumber }}/{{ spell.targetNumber + 2 }})</option>
            </select>
          </div>

          <div class="col-sm-4 mb-3">
            <select class="form-control" name="schoolSpell3" id="schoolSpell3" v-model="schoolSpell3">
              <option value="none">Select a spell</option>
              <option v-for="spell in schoolSpells" :value="spell.name" :key="spell.name" :disabled="inSpellbookNames.includes(spell.name)">{{ spell.name }} ({{ spell.targetNumber }}/{{ spell.targetNumber + 2 }})</option>
            </select>
          </div>

          <div class="col-sm-12 mb-3">
            <h4>Aligned Spells</h4>
          </div>

          <div class="col-sm-4 mb-3">
            <select class="form-control" name="alignedSpell1" id="alignedSpell1" v-model="alignedSpell1">
              <option value="none" class="bg-dark text-light" disabled>{{ alignedSchool1() }}</option>
              <option v-for="spell in alignedSpells1" :value="spell.name" :key="spell.name" :disabled="inSpellbookNames.includes(spell.name)">{{ spell.name }} ({{ spell.targetNumber + 2 }}/{{ spell.targetNumber + 4 }})</option>
            </select>
          </div>

          <div class="col-sm-4 mb-3">
            <select class="form-control" name="alignedSpell2" id="alignedSpell2" v-model="alignedSpell2">
              <option value="none" class="bg-dark text-light" disabled>{{ alignedSchool2() }}</option>
              <option v-for="spell in alignedSpells2" :value="spell.name" :key="spell.name" :disabled="inSpellbookNames.includes(spell.name)">{{ spell.name }} ({{ spell.targetNumber + 2 }}/{{ spell.targetNumber + 4 }})</option>
            </select>
          </div>

          <div class="col-sm-4 mb-3">
            <select class="form-control" name="alignedSpell3" id="alignedSpell3" v-model="alignedSpell3">
              <option value="none" class="bg-dark text-light" disabled>{{ alignedSchool3() }}</option>
              <option v-for="spell in alignedSpells3" :value="spell.name" :key="spell.name" :disabled="inSpellbookNames.includes(spell.name)">{{ spell.name }} ({{ spell.targetNumber + 2 }}/{{ spell.targetNumber + 4 }})</option>
            </select>
          </div>

          <div class="col-sm-12 mb-3">
            <h4>Neutral Spells</h4>
          </div>

          <div class="col-sm-4 mb-3">
            <select class="form-control" name="neutralSpell1" id="neutralSpell1" v-model="neutralSpell1">
              <option value="" class="bg-dark text-light" disabled>{{ getNeutralSchool1 }}</option>
              <option v-for="spell in neutralSpells1" :value="spell.name" :key="spell.name" :disabled="inNeutralSchool2.includes(spell.name) || inSpellbookNames.includes(spell.name)">{{ spell.name }} ({{ spell.targetNumber + 4 }}/{{ spell.targetNumber + 6 }})</option>
              <option value="" class="bg-dark text-light" disabled>{{ getNeutralSchool2 }}</option>
              <option v-for="spell in neutralSpells2" :value="spell.name" :key="spell.name" :disabled="inNeutralSchool2.includes(spell.name) || inSpellbookNames.includes(spell.name)">{{ spell.name }} ({{ spell.targetNumber + 4 }}/{{ spell.targetNumber + 6 }})</option>
              <option value="" class="bg-dark text-light" disabled>{{ getNeutralSchool3 }}</option>
              <option v-for="spell in neutralSpells3" :value="spell.name" :key="spell.name" :disabled="inNeutralSchool2.includes(spell.name) || inSpellbookNames.includes(spell.name)">{{ spell.name }} ({{ spell.targetNumber + 4 }}/{{ spell.targetNumber + 6 }})</option>
              <option value="" class="bg-dark text-light" disabled>{{ getNeutralSchool4 }}</option>
              <option v-for="spell in neutralSpells4" :value="spell.name" :key="spell.name" :disabled="inNeutralSchool2.includes(spell.name) || inSpellbookNames.includes(spell.name)">{{ spell.name }} ({{ spell.targetNumber + 4 }}/{{ spell.targetNumber + 6 }})</option>
              <option value="" class="bg-dark text-light" disabled>{{ getNeutralSchool5 }}</option>
              <option v-for="spell in neutralSpells5" :value="spell.name" :key="spell.name" :disabled="inNeutralSchool2.includes(spell.name) || inSpellbookNames.includes(spell.name)">{{ spell.name }} ({{ spell.targetNumber + 4 }}/{{ spell.targetNumber + 6 }})</option>
            </select>
          </div>

          <div class="col-sm-4 mb-3">
            <select class="form-control" name="neutralSpell2" id="neutralSpell2" v-model="neutralSpell2">
              <option value="" class="bg-dark text-light" disabled>{{ getNeutralSchool1 }}</option>
              <option v-for="spell in neutralSpells1" :value="spell.name" :key="spell.name" :disabled="inNeutralSchool1.includes(spell.name) || inSpellbookNames.includes(spell.name)">{{ spell.name }} ({{ spell.targetNumber + 4 }}/{{ spell.targetNumber + 6 }})</option>
              <option value="" class="bg-dark text-light" disabled>{{ getNeutralSchool2 }}</option>
              <option v-for="spell in neutralSpells2" :value="spell.name" :key="spell.name" :disabled="inNeutralSchool1.includes(spell.name) || inSpellbookNames.includes(spell.name)">{{ spell.name }} ({{ spell.targetNumber + 4 }}/{{ spell.targetNumber + 6 }})</option>
              <option value="" class="bg-dark text-light" disabled>{{ getNeutralSchool3 }}</option>
              <option v-for="spell in neutralSpells3" :value="spell.name" :key="spell.name" :disabled="inNeutralSchool1.includes(spell.name) || inSpellbookNames.includes(spell.name)">{{ spell.name }} ({{ spell.targetNumber + 4 }}/{{ spell.targetNumber + 6 }})</option>
              <option value="" class="bg-dark text-light" disabled>{{ getNeutralSchool4 }}</option>
              <option v-for="spell in neutralSpells4" :value="spell.name" :key="spell.name" :disabled="inNeutralSchool1.includes(spell.name) || inSpellbookNames.includes(spell.name)">{{ spell.name }} ({{ spell.targetNumber + 4 }}/{{ spell.targetNumber + 6 }})</option>
              <option value="" class="bg-dark text-light" disabled>{{ getNeutralSchool5 }}</option>
              <option v-for="spell in neutralSpells5" :value="spell.name" :key="spell.name" :disabled="inNeutralSchool1.includes(spell.name) || inSpellbookNames.includes(spell.name)">{{ spell.name }} ({{ spell.targetNumber + 4 }}/{{ spell.targetNumber + 6 }})</option>
            </select>
          </div>

          <div class="col-sm-12 mb-3">
            <div class="card parchment-card">
              <div class="card-body">
                <div v-if="selectedSpell">
                  <h4>{{ selectedSpell.name }}</h4>
                  <p>School: {{ getSchoolName(selectedSpell.school) }}</p>
                  <p>Type: {{ selectedSpell.type }}</p>
                  <p>TargetNumber: {{ selectedSpell.targetNumber }} / {{ selectedSpell.targetNumber + 2 }}</p>
                  <p>{{ selectedSpell.description }}</p>
                </div>
                <div v-else>
                  Choose a spell to see the details
                </div>
              </div>
            </div>
          </div>

        </div>

        <template #modal-footer>
          <div class="w-100 d-flex justify-content-end">
            <b-button variant="secondary" @click="handleClose">Close</b-button>
          </div>
        </template>
      </b-modal>
    </div>
  </template>
  
  <script>
  import { BButton, BModal, BFormSelect, BFormOption } from "bootstrap-vue";
  import { mapGetters, mapActions } from 'vuex';
  
  export default {
    name: "SelectSpellsModal",
    computed: {
      ...mapGetters(['getSchools', 'selectedSchool']),
      getSchoolName() {
        return (selectedSchool) => {
          const school = this.$store.state.schools.find((school) => school.id === selectedSchool);
          return school ? school.name : '';
        };
      },
      alignedSchool1(){           
        return () => {
          var selectedSchool = this.$store.state.selectedSchool;
          var alignedValue = this.getSchools.find(school => school.id === selectedSchool)?.aligned[0];
          var alignedSchool = this.getSchools.find(school => school.id === alignedValue);
          return alignedSchool ? alignedSchool.name : '';
        };
      },
      alignedSchool2(){          
        return () => {
          var selectedSchool = this.$store.state.selectedSchool;
          var alignedValue = this.getSchools.find(school => school.id === selectedSchool)?.aligned[1];
          var alignedSchool = this.getSchools.find(school => school.id === alignedValue);
          return alignedSchool ? alignedSchool.name : '';
        };
      },
      alignedSchool3(){           
        return () => {
          var selectedSchool = this.$store.state.selectedSchool;
          var alignedValue = this.getSchools.find(school => school.id === selectedSchool)?.aligned[2];
          var alignedSchool = this.getSchools.find(school => school.id === alignedValue);
          return alignedSchool ? alignedSchool.name : '';
        };
      },
      schoolOptions() {
        return this.getSchools.map(school => ({ value: school.id, text: school.name }));
      },
      schoolSpells() {
        var selectedSchool = this.$store.state.selectedSchool;
        return this.$store.state.spells.filter(spell => spell.school === selectedSchool);
      },
      alignedSpells1() {
        var selectedSchool = this.$store.state.selectedSchool;
        var alignedValue = this.getSchools.find(school => school.id === selectedSchool)?.aligned[0];
        return this.filterSpellsBySchool(alignedValue);
      },
      alignedSpells2() {
        var selectedSchool = this.$store.state.selectedSchool;
        var alignedValue = this.getSchools.find(school => school.id === selectedSchool)?.aligned[1];
        return this.filterSpellsBySchool(alignedValue);
      },
      alignedSpells3() {
        var selectedSchool = this.$store.state.selectedSchool;
        var alignedValue = this.getSchools.find(school => school.id === selectedSchool)?.aligned[2];
        return this.filterSpellsBySchool(alignedValue);
      },
      getNeutralSchool1() {
        var selectedSchool = this.$store.state.selectedSchool;
        var neutralValue = this.getSchools.find(school => school.id === selectedSchool)?.neutral[0];
        var neutralSchool = this.getSchools.find(school => school.id === neutralValue);
        return neutralSchool ? neutralSchool.name : '';
      },
      getNeutralSchool2() {  
        var selectedSchool = this.$store.state.selectedSchool;
        var neutralValue = this.getSchools.find(school => school.id === selectedSchool)?.neutral[1];
        var neutralSchool = this.getSchools.find(school => school.id === neutralValue);
        return neutralSchool ? neutralSchool.name : '';
      },
      getNeutralSchool3() {      
        var selectedSchool = this.$store.state.selectedSchool;
        var neutralValue = this.getSchools.find(school => school.id === selectedSchool)?.neutral[2];
        var neutralSchool = this.getSchools.find(school => school.id === neutralValue);
        return neutralSchool ? neutralSchool.name : '';
      },
      getNeutralSchool4() {   
        var selectedSchool = this.$store.state.selectedSchool;
        var neutralValue = this.getSchools.find(school => school.id === selectedSchool)?.neutral[3];
        var neutralSchool = this.getSchools.find(school => school.id === neutralValue);
        return neutralSchool ? neutralSchool.name : '';
      },
      getNeutralSchool5() {  
        var selectedSchool = this.$store.state.selectedSchool;
        var neutralValue = this.getSchools.find(school => school.id === selectedSchool)?.neutral[4];
        var neutralSchool = this.getSchools.find(school => school.id === neutralValue);
        return neutralSchool ? neutralSchool.name : '';
      },
      neutralSpells1() {
        var selectedSchool = this.$store.state.selectedSchool;
        var neutralValue = this.getSchools.find(school => school.id === selectedSchool)?.neutral[0];
        return this.filterSpellsBySchool(neutralValue);
      },
      neutralSpells2() {
        var selectedSchool = this.$store.state.selectedSchool;
        var neutralValue = this.getSchools.find(school => school.id === selectedSchool)?.neutral[1];
        return this.filterSpellsBySchool(neutralValue);
      },
      neutralSpells3() {
        var selectedSchool = this.$store.state.selectedSchool;
        var neutralValue = this.getSchools.find(school => school.id === selectedSchool)?.neutral[2];
        return this.filterSpellsBySchool(neutralValue);
      },
      neutralSpells4() {
        var selectedSchool = this.$store.state.selectedSchool;
        var neutralValue = this.getSchools.find(school => school.id === selectedSchool)?.neutral[3];
        return this.filterSpellsBySchool(neutralValue);
      },
      neutralSpells5() {
        var selectedSchool = this.$store.state.selectedSchool;
        var neutralValue = this.getSchools.find(school => school.id === selectedSchool)?.neutral[4];
        return this.filterSpellsBySchool(neutralValue);
      },
      inSpellbook() {
        var spellbook = []
        if (this.$store.state.schoolSpell1 && this.$store.state.schoolSpell1.name) {
          spellbook.push(this.$store.state.schoolSpell1)
        }
        if (this.$store.state.schoolSpell2 && this.$store.state.schoolSpell2.name) {
          spellbook.push(this.$store.state.schoolSpell2)
        }
        if (this.$store.state.schoolSpell3 && this.$store.state.schoolSpell3.name) {
          spellbook.push(this.$store.state.schoolSpell3)
        }        
        if (this.$store.state.alignedSpell1 && this.$store.state.alignedSpell1.name) {
          spellbook.push(this.$store.state.alignedSpell1)
        }     
        if (this.$store.state.alignedSpell2 && this.$store.state.alignedSpell2.name) {
          spellbook.push(this.$store.state.alignedSpell2)
        }     
        if (this.$store.state.alignedSpell3 && this.$store.state.alignedSpell3.name) {
          spellbook.push(this.$store.state.alignedSpell3)
        }
        if (this.$store.state.neutralSpell1 && this.$store.state.neutralSpell1.name) {
          spellbook.push(this.$store.state.neutralSpell1)
        }
        if (this.$store.state.neutralSpell2 && this.$store.state.neutralSpell2.name) {
          spellbook.push(this.$store.state.neutralSpell2)
        }
        if (this.$store.state.neutralSpell1 && this.$store.state.neutralSpell1.name) {
          spellbook.push(this.$store.state.neutralSpell1);
        }
        if (this.$store.state.neutralSpell2 && this.$store.state.neutralSpell2.name) {
          spellbook.push(this.$store.state.neutralSpell2);
        }
        return spellbook
      },  
      inSpellbookNames() {
        return this.inSpellbook.map(spell => spell.name);
      },
      inNeutralSchool1() {
        const neutralSchoolId = this.$store.state.neutralSpell1?.school;
        if (!neutralSchoolId) {
          return []; // Return an empty array if neutralSchool1 is null
        }
        return this.$store.state.spells
          .filter(spell => spell.school === neutralSchoolId)
          .map(spell => spell.name);
      },
      inNeutralSchool2() {
        const neutralSchoolId = this.$store.state.neutralSpell2?.school;
        if (!neutralSchoolId) {
          return []; // Return an empty array if neutralSchool1 is null
        }
        return this.$store.state.spells
          .filter(spell => spell.school === neutralSchoolId)
          .map(spell => spell.name);
      },
      selectedSchool: {
        get() {
          return this.$store.getters.selectedSchool;
        },
        set(value) {
          this.setSelectedSchool(value);
        },
      },
      schoolSpell1: {
        get() {
          const spellName = this.$store.state.schoolSpell1?.name;
          return spellName ? spellName : 'Select a spell';
        },
        set(value) {
          this.$store.commit('setSchoolSpell1', value);
        },
      },
      schoolSpell2: {
        get() {
          const spellName = this.$store.state.schoolSpell2?.name;
          return spellName ? spellName : 'Select a spell';
        },
        set(value) {
          this.$store.commit('setSchoolSpell2', value);
        },
      },
      schoolSpell3: {
        get() {
          const spellName = this.$store.state.schoolSpell3?.name;
          return spellName ? spellName : 'Select a spell';
        },
        set(value) {
          this.$store.commit('setSchoolSpell3', value);
        },
      },
      alignedSpell1: {
        get() {
          const spellName = this.$store.state.alignedSpell1?.name;
          return spellName ? spellName : 'Select a spell';
        },
        set(value) {
          this.$store.commit('setAlignedSpell1', value);
        },
      },
      alignedSpell2: {
        get() {
          const spellName = this.$store.state.alignedSpell2?.name;
          return spellName ? spellName : 'Select a spell';
        },
        set(value) {
          this.$store.commit('setAlignedSpell2', value);
        },
      },
      alignedSpell3: {
        get() {
          const spellName = this.$store.state.alignedSpell3?.name;
          return spellName ? spellName : 'Select a spell';
        },
        set(value) {
          this.$store.commit('setAlignedSpell3', value);
        },
      },
      neutralSpell1: {
        get() {
          const spellName = this.$store.state.neutralSpell1?.name;
          return spellName ? spellName : 'Select a spell';
        },
        set(value) {
          this.$store.commit('setNeutralSpell1', value);
        },
      },
      neutralSpell2: {
        get() {
          const spellName = this.$store.state.neutralSpell2?.name;
          return spellName ? spellName : 'Select a spell';
        },
        set(value) {
          this.$store.commit('setNeutralSpell2', value);
        },
      },
    },
    data() {
      return {        
        schools: this.getSchools,
        selectedSpell: null,
      };
    },
    props: {
      modalId: {
        type: String,
        required: true,
      },
    },
    components: {
      BButton,
      BModal,
      BFormSelect
    },
    methods: {
      ...mapActions(['setSelectedSchool']),
      updateSelectedSchool() {
        this.setSelectedSchool(this.selectedSchool);
      },
      handleClose() {
        this.$bvModal.hide(this.modalId);
      },    
      filterSpellsBySchool(schoolId) {
        return this.$store.state.spells.filter(spell => spell.school === schoolId);
      },
    },
    watch: {
      schoolSpell1(value) {
        if (value !== 'none') {
          this.selectedSpell = this.$store.state.schoolSpell1;
        }
      },
      schoolSpell2(value) {
        if (value !== 'none') {
          this.selectedSpell = this.$store.state.schoolSpell2;
        }
      },
      schoolSpell3(value) {
        if (value !== 'none') {
          this.selectedSpell = this.$store.state.schoolSpell3;
        }
      },
      alignedSpell1(value) {
        if (value !== 'none') {
          this.selectedSpell = this.$store.state.alignedSpell1;
        }
      },
      alignedSpell2(value) {
        if (value !== 'none') {
          this.selectedSpell = this.$store.state.alignedSpell2;
        }
      },
      alignedSpell3(value) {
        if (value !== 'none') {
          this.selectedSpell = this.$store.state.alignedSpell3;
        }
      },
      neutralSpell1(value) {
        if (value !== 'none') {
          this.selectedSpell = this.$store.state.neutralSpell1;
        }
      },
      neutralSpell2(value) {
        if (value !== 'none') {
          this.selectedSpell = this.$store.state.neutralSpell2;
        }
      },
    },

    mounted() {
    },
  };
  </script>
  