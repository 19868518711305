<template>
  <WarbandManager/>
</template>

<script>
import WarbandManager from './components/WarbandManager.vue';

export default {
  name: "App",
  components: {
    WarbandManager,
  },
  // mounted() {
  //   this.disableZoomOnDoubleTap();
  // },
  // methods: {
  //   disableZoomOnDoubleTap() {
  //     const preventDefault = (event) => {
  //       if (event.touches.length > 1) {
  //         event.preventDefault();
  //       }
  //     };

  //     document.addEventListener('touchstart', preventDefault, { passive: false });

  //     // Remove the event listener when the component is destroyed
  //     this.$once('hook:beforeDestroy', () => {
  //       document.removeEventListener('touchstart', preventDefault);
  //     });
  //   },
  // },
};
</script>

<style>
html, body {
  touch-action: manipulation;
}
html, body {
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
  overflow: hidden;
}
body{
  font-family: "IM Fell DW Pica", serif!important;
  font-weight: 400;
  font-style: normal;
  padding: 1rem;
  overflow: hidden;
}
a{
  color: #965043!important;
}
h1, h2, h3, h4, h5, h6 {
  color: #965043!important;
}
.card{
  background-color: #fff3bb!important; /* Parchment background color */
  border: 1px solid #d3c0a0!important; /* Parchment border color */
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2)!important; /* Parchment box shadow */
}
.parchment-card {
  background-color: #f5e5c7!important; /* Parchment background color */
  border: 1px solid #d3caa0!important; /* Parchment border color */
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2)!important; /* Parchment box shadow */
  /* padding: 15px!important; Adjust as needed */
  /* Add more styles as needed for your parchment effect */
}
.modal-content {
  background-color: #fff3bb!important;
}
.modal-title{
  font-weight: bold;
}
.card-header{
  background-color: #dbd1a3!important;
}
.nav-tabs .nav-link.active {
  background-color: #fff3bb!important;
  font-weight: bold;
}
.nav-tabs{
  font-size: 1.3rem;
}
.table th, .table td{
  border-color: #965043!important;
}
.btn {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  padding: 6px 12px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}
.btn-danger{  
  background-image: linear-gradient(to bottom, #ff0000, #ff7f00);
  border: 2px solid #ff0000;
}
.btn-danger:hover {
  background-image: linear-gradient(to bottom, #ff0000, #ff0000);
  cursor: pointer;
}
.btn-health{  
  background-image: linear-gradient(to bottom, #c1bfbf, #ebe9e9);
  color: black;
  border: 2px solid #c1bfbf;
}
.btn-health:hover {
  background-image: linear-gradient(to bottom, #c1bfbf, #c1bfbf);
  color: black;
  cursor: pointer;
}
.btn-warning{  
  background-image: linear-gradient(to bottom, #ffd000, #ff7f00);
  border: 2px solid #ffd000;
}
.btn-warning:hover {
  background-image: linear-gradient(to bottom, #ffd000, #ffd000);
  cursor: pointer;
}
.btn-success{  
  background-image: linear-gradient(to bottom, #1d960d, #a8ff06);
  border: 2px solid #1d960d;
}
.btn-success:hover {
  background-image: linear-gradient(to bottom, #1d960d, #1d960d);
  cursor: pointer;
}
.btn-primary{  
  background-image: linear-gradient(to bottom, #0d5f96, #06ffde);
  border: 2px solid #0d5f96;
}
.btn-primary:hover {
  background-image: linear-gradient(to bottom, #0d5f96, #0d5f96);
  cursor: pointer;
}
.btn-bury{  
  background-image: linear-gradient(to bottom, #000000, #343434);
  border: 2px solid #000000;
}
.btn-bury:hover {
  background-image: linear-gradient(to bottom, #000000, #000000);
  cursor: pointer;
}
.form-control, .custom-select{
  background-color: #f5e5c7!important; /* Parchment background color */
  border: 1px solid #d3caa0!important;
  color: black!important;
}
.form-control:disabled{
  background-color: #fad592!important;
}
select, input, .custom-select{
  height: 3rem!important;
}
</style>