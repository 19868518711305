<template>
  <div id="app">
      <div id="title">
        <h3 class="text-light">Warband Manager</h3>
      </div>
      <div id="stats" class="text-center text-md-right d-flex justify-content-end align-items-center">
        <h4 class="text-light mr-2">Gold Available: {{ this.$store.state.gold }}</h4>
        <b-button @click="openBaseModal" class="btn btn-warning mb-2 mr-2">
          <b-icon icon="house-fill" class="text-light"></b-icon>
        </b-button>
        <b-button @click="openLevelUpModal" class="btn btn-success mb-2 mr-2">
          <b-icon icon="arrow-up-circle-fill" class="text-light"></b-icon>
        </b-button>
        <b-button @click="openSettingsModal" class="btn btn-secondary mb-2">
          <b-icon icon="gear-fill" class="text-light"></b-icon>
        </b-button>
      </div>

      <div id="warband">
        <b-card no-body>
          <b-tabs card>
            <b-tab title="Wizard" active>
              <b-card-text>
                 <Wizard />
                <Apprentice v-show="hasApprentice"/>

              </b-card-text>
            </b-tab>

            <b-tab title="Mercenaries">
              <b-card-text>
                  <WarbandTable />
              </b-card-text>
            </b-tab>

            <b-tab title="Spells">
              <b-card-text>
                  <Spellbook />
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
        
        <!-- Modals -->
        <SoldierModal
          :modalId="'selectSoldierModal'"
          :gold="Number(this.$store.state.gold)"
          :soldiers="soldiers"
          @show-toast="showCustomToast"
        />
        
        <SettingsModal
          :settingsModal="'settingsModal'"
          @show-toast="showCustomToast"
        />
        
        <LevelUpModal
          :levelUpModal="'levelUpModal'"
          @show-toast="showCustomToast"
        />
        
        <BaseModal
          :baseModal="'baseModal'"
          :gold="Number(this.$store.state.gold)"
          @show-toast="showCustomToast"
        />

        <SelectSpellsModal :modalId="'selectSpellsModal'" @show-toast="showCustomToast" />

        <toast-container
          :toast-messages="toastMessages"
          @hide-toast="hideToast"
        ></toast-container>
      </div>
  </div>
</template>

<script>
import { BButton, BIcon } from "bootstrap-vue";
import { mapState, mapActions } from 'vuex';
import Wizard from './Wizard.vue';
import Apprentice from './Apprentice.vue';
import WarbandTable from './WarbandTable.vue';
import ToastContainer from "./ToastContainer.vue";
import SoldierModal from './SoldierModal.vue';
import Spellbook from './Spellbook.vue';
import SelectSpellsModal from './SelectSpellsModal.vue';
import SettingsModal from './SettingsModal.vue';
import LevelUpModal from './LevelUpModal.vue';
import BaseModal from './BaseModal.vue';

export default {
  name: "WarbandManager",
  data() {
    return {
      gold: this.$store.state.gold,
      showToast: false,
      toastMessages: [],
      settingsModal: 'settingsModal',
    };
  },
  components: {
    BButton,
    Wizard,
    WarbandTable,
    ToastContainer,
    SoldierModal,
    Apprentice,
    Spellbook,
    SelectSpellsModal,
    SettingsModal,
    LevelUpModal,
    BaseModal,
    BIcon
  },
  methods: {
    showCustomToast(text, color) {
      const message = {
        text: text || "Custom toast message",
        color: color, // Set the default color to 'success'
      };

      this.toastMessages.push(message);

      // Automatically hide the toast after 3 seconds (adjust as needed)
      setTimeout(() => {
        this.hideToast(0); // Hide the first toast in the array after the specified time
      }, 3000);
    },
    hideToast(index) {
      this.toastMessages.splice(index, 1);
    },
    ...mapActions(["resetStore"]),
    openLevelUpModal() {
      this.$bvModal.show('levelUpModal');
    },
    openSettingsModal() {
      this.$bvModal.show('settingsModal');
    },
    openBaseModal() {
      this.$bvModal.show('baseModal');
    },
  },
  computed: {
    ...mapState(['hasApprentice']),
    soldiers() {
      return this.$store.state.soldiers; // Retrieve the soldiers from the store
    },
  },
  mounted() {
    this.$store.dispatch("getSoldiers");
    this.$store.dispatch("getSchools");
    this.$store.dispatch("getSpells");
    this.$store.dispatch("getBases");
    this.$store.dispatch("getBaseResources");
  },
};
</script>
<style>
#app {
  height: 100vh;
  max-height: 100vh;
  display: grid;
  grid-template-rows: min-content auto;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "title stats" "warband warband";
}
#title {
  grid-area: title;
}
#stats {
  grid-area: stats;
}
#warband {
  grid-area: warband;
  max-height: calc(100vh - 5rem);
}
.card {
  flex-grow: 1;
  height: 100%;
}
.tabs {
  height: 100%;
}
.card {
  flex-grow: 1;
  max-height: 100%;
}
.tab-content {
  overflow-y: auto; /* Add this line to enable vertical scrolling */
  max-height: calc(100% - 4.5rem);
}
.card-body {
  max-height: 100%;
}
.card-text {
  max-height: 100%;
}
.modal-xxl {
  max-width: 95% !important;
  margin: auto;
}
</style>
