<template>
    <div>
        <b-modal
            :id="learnSpellModal"
            :title="'Learn Spell from Grimoire'"
        >
            <div class="row">
                <div class="col-sm-12">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">Spell to learn</span>
                        </div>
                        <select v-model="selectedSpell" class="form-control">
                            <option v-for="spell in unknownSpells" :key="spell.name" :value="spell">
                                {{ spell.name }}, {{ spell.schoolName }}, {{ spell.targetNumber }} 
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div v-if="selectedSpell">
                        <h4>{{ selectedSpell.name }}</h4>
                        <p>School: {{ selectedSpell.schoolName }}</p>
                        <p>Type: {{ selectedSpell.type }}</p>
                        <p>TargetNumber: {{ selectedSpell.targetNumber }} / {{ selectedSpell.targetNumber + 2 }}</p>
                        <p>{{ selectedSpell.description }}</p>
                    </div>
                    <div v-else>
                        Choose a spell to see the details
                    </div>
                </div>
            </div>
            <template #modal-footer>
                <div class="d-flex justify-content-end w-100">
                    <b-button variant="secondary" @click="handleClose">Close</b-button>
                    <b-button variant="success" @click="handleLearnSpell" class="ml-3">Learn Spell</b-button>
                </div>
            </template>
        </b-modal>
  
    </div>
  </template>
    
    <script>
    import { BButton, BModal } from "bootstrap-vue";
    
    export default {
        name: "LearnSpellModal",
        data() {
            return {
                selectedSpell: null
            };
        },
        props: {
            learnSpellModal: {
                type: String,
                required: true
            },
        },
        components: {
            BButton,
            BModal
        },
        computed: {
            knownSpells() {
                // Get the names of the known spells
                let spells = [
                    this.$store.state.schoolSpell1.name,
                    this.$store.state.schoolSpell2.name,
                    this.$store.state.schoolSpell3.name,
                    this.$store.state.alignedSpell1.name,
                    this.$store.state.alignedSpell2.name,
                    this.$store.state.alignedSpell3.name,
                    this.$store.state.neutralSpell1.name,
                    this.$store.state.neutralSpell2.name
                ];

                // Get the names of the additional spells
                let additionalSpells = this.$store.state.additionalSpells.map(spell => spell.name);

                // Combine the two arrays
                return spells.concat(additionalSpells);
            },
            allSpells() {
                const selectedSchool = this.$store.state.selectedSchool;
                const schools = this.$store.state.schools;

                // If there is no selected school, return all spells without any modifications
                if (!selectedSchool) {
                    return this.$store.state.spells || [];
                }

                const currentSchool = schools.find(school => school.id === selectedSchool);

                if (!this.$store.state.spells) {
                    return [];
                }

                return this.$store.state.spells.map(spell => {
                    let targetNumberAdjustment = 0;
                    let spellSchoolName = '';

                    if (spell.school === selectedSchool) {
                        targetNumberAdjustment = 0;
                        spellSchoolName = currentSchool.name;
                    } else {
                        const spellSchool = schools.find(school => school.id === spell.school);
                        if (spellSchool) {
                            spellSchoolName = spellSchool.name;
                            if (currentSchool.aligned.includes(spell.school)) {
                                targetNumberAdjustment = 2;
                            } else if (currentSchool.neutral.includes(spell.school)) {
                                targetNumberAdjustment = 4;
                            } else {
                                targetNumberAdjustment = 6;
                            }
                        }
                    }

                    return {
                        ...spell,
                        targetNumber: spell.targetNumber + targetNumberAdjustment,
                        schoolName: spellSchoolName
                    };
                });
            },
            unknownSpells() {
                return this.allSpells.filter(spell => !this.knownSpells.includes(spell.name));
            }
        },
        methods: {   
            handleClose() {
                this.$bvModal.hide(this.learnSpellModal);
            },
            handleLearnSpell() {
                if (!this.selectedSpell) {
                    alert('Please select a spell');
                    return;
                }

                if (confirm(`You are about to learn ${this.selectedSpell.name}, this cannot be undone. Continue?`)) {
                    this.$store.commit('learnSpell', this.selectedSpell);
                    this.$bvModal.hide(this.learnSpellModal);
                }
            }
        }
    };
    </script>
    