<template>
    <div class="row">
      <div class="col-sm-8 mb-3">
        <h3>{{ getSchoolName(selectedSchool) }} Spellbook</h3>
      </div>

      <div class="col-sm-4 text-right mb-3">
        <b-button v-b-modal="'selectSpellsModal'" class="btn btn-success">Select Spells</b-button>
      </div>

      <div class="col-sm-12">
        <div class="table-responsive">
          <table class="table w-100">
            <thead>
              <tr>
                <th>Spell</th>
                <th>School</th>
                <th>Type</th>
                <th class="text-center">Target (W/A)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="spell in spellbook" :key="spell.id" @click="showSpellDetails(spell)" :class="{ 'hover-pointer': selectedSpell === null && hoveredSpell !== spell, 'hover-row': hoveredSpell === spell }" @mouseenter="hoveredSpell = spell" @mouseleave="hoveredSpell = null">
                <td>{{ spell.name }}</td>
                <td>{{ getSchoolName(spell.school) }}</td>
                <td>{{ spell.type }}</td>
                <td class="text-center">{{ spell.targetNumber }} / {{ spell.targetNumber + 2 }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="col-sm-12">
        <div class="card parchment-card">
          <div class="card-body">            
            <div v-if="selectedSpell">
              <h4>{{ selectedSpell.name }}</h4>
              <p>School: {{ getSchoolName(selectedSpell.school) }}</p>
              <p>Type: {{ selectedSpell.type }}</p>
              <p>TargetNumber: {{ selectedSpell.targetNumber }} / {{ selectedSpell.targetNumber + 2 }}</p>
              <p>{{ selectedSpell.description }}</p>
            </div>
            <div v-else>
              Choose a spell to see the details
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      selectedSpell: null,
      hoveredSpell: null,
    };
  },
  computed: {
    getSchoolName() {
      return (selectedSchool) => {
        const school = this.$store.state.schools.find((school) => school.id === selectedSchool);
        return school ? school.name : '';
      };
    },
    selectedSchool() {
      return this.$store.state.selectedSchool;
    },    
    spellbook() {
      var spellbook = []
      if (this.$store.state.schoolSpell1 && this.$store.state.schoolSpell1.name) {
        spellbook.push(this.$store.state.schoolSpell1)
      }
      if (this.$store.state.schoolSpell2 && this.$store.state.schoolSpell2.name) {
        spellbook.push(this.$store.state.schoolSpell2)
      }
      if (this.$store.state.schoolSpell3 && this.$store.state.schoolSpell3.name) {
        spellbook.push(this.$store.state.schoolSpell3)
      }        
      if (this.$store.state.alignedSpell1 && this.$store.state.alignedSpell1.name) {
        spellbook.push(this.$store.state.alignedSpell1)
      }     
      if (this.$store.state.alignedSpell2 && this.$store.state.alignedSpell2.name) {
        spellbook.push(this.$store.state.alignedSpell2)
      }     
      if (this.$store.state.alignedSpell3 && this.$store.state.alignedSpell3.name) {
        spellbook.push(this.$store.state.alignedSpell3)
      }
      if (this.$store.state.neutralSpell1 && this.$store.state.neutralSpell1.name) {
        spellbook.push(this.$store.state.neutralSpell1)
      }
      if (this.$store.state.neutralSpell2 && this.$store.state.neutralSpell2.name) {
        spellbook.push(this.$store.state.neutralSpell2)
      }
      if (this.$store.state.additionalSpells && this.$store.state.additionalSpells.length > 0) {
        spellbook = spellbook.concat(this.$store.state.additionalSpells);
      }

      return spellbook
    },
  },
  methods: {
    showSpellDetails(spell) {
      console.log('click')
      this.selectedSpell = spell;
    },
  },
};
</script>

<style>
.hover-pointer {
  cursor: pointer;
}

.hover-row {
  background-color: rgb(226, 226, 226); /* Set your desired background color */
}
</style>
