<template>
    <div>
        <b-modal
            :id="addSpellModal"
            :title="'Add Spell'"
            size="xxl"
        >
        <div class="row">

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">Name</span>
                    </div>
                    <input type="text" class="form-control" v-model="name">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <b-form-select v-model="school" :options="schoolOptions">
                    <template #first>
                        <b-form-select-option :value="null" disabled>Please select an option</b-form-select-option>
                    </template>
                </b-form-select>
            </div>

            <div class="col-xs-6 col-sm-6">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">Type</span>
                    </div>
                    <input type="text" class="form-control" v-model="type">
                </div>
            </div>

            <div class="col-xs-6 col-sm-6">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">Wizard Target Number</span>
                    </div>
                    <input type="number" min="5" step="1" class="form-control text-right" v-model.number="targetNumber">
                </div>
            </div>

            <div class="col-sm-12">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">Description</span>
                    </div>
                    <textarea class="form-control" v-model="description" rows="5"></textarea>
                </div>
            </div>
        
        </div>       
        
        <template #modal-footer>
          <div class="d-flex justify-content-end w-100">            
            <b-button variant="success" @click="addSpell">Save</b-button>            
            <b-button variant="secondary" class="ml-2" @click="handleClose">Close</b-button>
          </div>
        </template>
      </b-modal>
    </div>
  </template>
    
    <script>
    import { BButton, BModal } from "bootstrap-vue";
    import { mapGetters, mapActions } from 'vuex';
    
    export default {
      name: "AddSpellModal",
      data() {
        return {       
            schools: this.getSchools, 
            school: 0,     
            name: '',
            type: '',
            targetNumber: 5,
            description: ''
        };
      },
      computed: {  
        ...mapGetters(['getSchools', 'selectedSchool']),
        schoolOptions() {
            return this.getSchools.map(school => ({ value: school.id, text: school.name }));
        },
      },
      props: {
        addSpellModal: {
          type: String,
          required: true
        },
      },
      components: {
        BButton,
        BModal,
      },
      methods: { 
        handleClose() {
          this.$bvModal.hide(this.addSpellModal);
        },
        addSpell() {
            const newSpell = {
                school: this.school,
                name: this.name,
                description: this.description,
                targetNumber: this.targetNumber,
                type: this.type,
            };

            this.$store.commit("addSpell", newSpell);

            // Clear the form data after saving the soldier
            this.school = 0,
            this.name = '',
            this.description = '',
            this.targetNumber = 5,
            this.type = '',

            // Close the modal after saving
            this.$bvModal.hide(this.addSpellModal);
            alert("New spell added to Codex.")

        },
      }
    };
    </script>
    