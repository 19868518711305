<template>
    <div>
      <b-modal
        :id="selectSoldierModal"
        :title="'Select Soldiers - Gold Available: ' + gold + 'gc'"
        size="xxl"
        scrollable
      >
        <div class="table-reponsive">
          <table class="table w-100">
            <thead>
              <tr>
                <th>Class</th>
                <th class="text-center">Move</th>
                <th class="text-center">Fight</th>
                <th class="text-center">Shoot</th>
                <th class="text-center">Armour</th>
                <th class="text-center">Will</th>
                <th class="text-center">Health</th>
                <th class="text-right">Cost</th>
                <th>Notes</th>
                <th class="text-center">Type</th>
                <th class="text-center">Select</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="soldier in soldiers" :key="soldier.id">
                <td class="font-weight-bold">{{ soldier.class }}</td>
                <td class="text-center">{{ soldier.move }}</td>
                <td class="text-center">{{ soldier.fight }}</td>
                <td class="text-center">{{ soldier.shoot }}</td>
                <td class="text-center">{{ soldier.armour }}</td>
                <td class="text-center">{{ soldier.will }}</td>
                <td class="text-center">{{ soldier.health }}</td>
                <td class="text-right">{{ soldier.cost }} GC</td>
                <td>{{ soldier.notes }}</td>
                <td class="text-center">{{ soldier.type }}</td>
                <td>
                  <div class="d-grid gap-2">
                    <b-button
                      block
                      class="btn btn-success"
                      @click="addToWarband(soldier)"
                    >
                      Select
                    </b-button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <template #modal-footer>
          <div class="w-100 d-flex justify-content-end">
            <b-button variant="secondary" @click="handleClose">Close</b-button>
          </div>
        </template>

      </b-modal>
    </div>
  </template>
  
  <script>
  import { BButton, BModal } from "bootstrap-vue";
  
  export default {
    name: "SoldierModal",
    props: {
      modalId: {
        type: String,
        required: true
      },
      gold: {
        type: Number,
        required: true,
      },
      soldiers: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        // Define the selectSoldierModal property
        selectSoldierModal: this.modalId
      };
    },
    components: {
      BButton,
      BModal,
    },
    methods: {      
      addToWarband(soldier) {
        // Same implementation as in the WarbandManager component
        const gold = this.$store.state.gold;
        const myBaseResources = this.$store.state.myBaseResources;
        let soldierCost = soldier.cost;

        // Check if myBaseResources contains an object with resource='Carrier Pigeon'
        if (myBaseResources.some(resource => resource.resource === 'Carrier Pigeons')) {
          soldierCost = Math.max(0, soldierCost - 10);
        }

        const updatedGold = gold - soldierCost;
        var message;
        var color;

        if (this.$store.state.warband.length < 8) {
          if (updatedGold >= 0) {
            if (this.countSpecialistSoldiers() < 4 && soldier.type === "specialist") {
              const clonedSoldier = { ...soldier };
              this.$store.commit("addSoldier", clonedSoldier);
              this.$store.commit("updateGold", updatedGold);
              message = soldier.class + " reporting for duty!";
              color = "success";
            } else if (soldier.type !== "specialist") {
              const clonedSoldier = { ...soldier };
              this.$store.commit("addSoldier", clonedSoldier);
              this.$store.commit("updateGold", updatedGold);
              message = soldier.class + " reporting for duty!";
              color = "success";
            } else {
              message = "Maximum number of specialist soldiers reached!";
              color = "danger";
            }
          } else {
            message = "You can't afford a " + soldier.class + ", you're too damn poor!!!";
            color = "danger";
          }
        } else {
          message = "Don't you have enough already? Hiring request denied!!!";
          color = "danger";
        }

        // Call the showCustomToast method of the WarbandManager component
        this.$emit("show-toast", message, color);
      },
      countSpecialistSoldiers() {
        return this.$store.state.warband.filter((soldier) => soldier.type === "specialist").length;
      },
      handleClose() {
        this.$bvModal.hide(this.modalId);
      }
    }
  };
  </script>
  