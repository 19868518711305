<template>
  <div>
    <b-modal :id="levelUpModal" :title="'Level Up'" size="xxl" scrollable>
      <div class="row">
        <div class="col-sm-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">Gold</span>
            </div>
            <input
              type="number"
              min="0"
              step="1"
              class="form-control"
              v-model="gold"
              @input="updateGold($event)"
            />
          </div>
        </div>

        <div class="col-sm-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">Experience</span>
            </div>
            <input
              type="number"
              min="0"
              step="1"
              class="form-control"
              v-model="wizard.xp"
              @input="updateWizardProperty('xp', Number($event))"
            />
          </div>
        </div>

        <div class="col-sm-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">Level</span>
            </div>
            <input
              type="number"
              min="0"
              step="1"
              class="form-control"
              v-model="wizard.level"
              @input="updateWizardProperty('level', Number($event))"
            />
          </div>
        </div>

        <hr />

        <div class="col-sm-12"><h4>Standard Spells</h4></div>

        <!-- default spells -->
        <div class="col-sm-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">{{
                schoolSpell1.name
              }}</span>
            </div>
            <input
              type="number"
              min="5"
              step="1"
              class="form-control"
              :value="schoolSpell1.targetNumber"
              @input="
                schoolSpell1 = {
                  ...schoolSpell1,
                  targetNumber: Number($event.target.value),
                }
              "
            />
          </div>
        </div>

        <div class="col-sm-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">{{
                schoolSpell2.name
              }}</span>
            </div>
            <input
              type="number"
              min="5"
              step="1"
              class="form-control"
              :value="schoolSpell2.targetNumber"
              @input="
                schoolSpell2 = {
                  ...schoolSpell2,
                  targetNumber: Number($event.target.value),
                }
              "
            />
          </div>
        </div>

        <div class="col-sm-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">{{
                schoolSpell3.name
              }}</span>
            </div>
            <input
              type="number"
              min="5"
              step="1"
              class="form-control"
              :value="schoolSpell3.targetNumber"
              @input="
                schoolSpell3 = {
                  ...schoolSpell3,
                  targetNumber: Number($event.target.value),
                }
              "
            />
          </div>
        </div>

        <div class="col-sm-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">{{
                alignedSpell1.name
              }}</span>
            </div>
            <input
              type="number"
              min="5"
              step="1"
              class="form-control"
              :value="alignedSpell1.targetNumber"
              @input="
                alignedSpell1 = {
                  ...alignedSpell1,
                  targetNumber: Number($event.target.value),
                }
              "
            />
          </div>
        </div>

        <div class="col-sm-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">{{
                alignedSpell2.name
              }}</span>
            </div>
            <input
              type="number"
              min="5"
              step="1"
              class="form-control"
              :value="alignedSpell2.targetNumber"
              @input="
                alignedSpell2 = {
                  ...alignedSpell2,
                  targetNumber: Number($event.target.value),
                }
              "
            />
          </div>
        </div>

        <div class="col-sm-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">{{
                alignedSpell3.name
              }}</span>
            </div>
            <input
              type="number"
              min="5"
              step="1"
              class="form-control"
              :value="alignedSpell3.targetNumber"
              @input="
                alignedSpell3 = {
                  ...alignedSpell3,
                  targetNumber: Number($event.target.value),
                }
              "
            />
          </div>
        </div>

        <div class="col-sm-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">{{
                neutralSpell1.name
              }}</span>
            </div>
            <input
              type="number"
              min="5"
              step="1"
              class="form-control"
              :value="neutralSpell1.targetNumber"
              @input="
                neutralSpell1 = {
                  ...neutralSpell1,
                  targetNumber: Number($event.target.value),
                }
              "
            />
          </div>
        </div>

        <div class="col-sm-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">{{
                neutralSpell2.name
              }}</span>
            </div>
            <input
              type="number"
              min="5"
              step="1"
              class="form-control"
              :value="neutralSpell2.targetNumber"
              @input="
                neutralSpell2 = {
                  ...neutralSpell2,
                  targetNumber: Number($event.target.value),
                }
              "
            />
          </div>
        </div>

        <div class="col-sm-8">
          <h4>Additional Spells</h4>
        </div>

        <div class="col-sm-4 d-flex justify-content-end align-items-center">
          <b-button @click="openLearnSpellModal" class="btn btn-success mb-2">
            Learn Spell From Grimoire
            <b-icon icon="plus-circle-fill" class="text-light"></b-icon>
          </b-button>
        </div>

        <div
          class="col-sm-3"
          v-for="(spell, index) in additionalSpells"
          :key="index"
        >
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">{{
                spell.name
              }}</span>
            </div>
            <input
              type="number"
              min="5"
              step="1"
              class="form-control"
              v-model.number="spell.targetNumber"
            />
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="secondary" @click="handleClose">Close</b-button>
        </div>
      </template>
    </b-modal>

    <learn-spell-modal :learnSpellModal="'learnSpellModal'" />
  </div>
</template>

<script>
import { BButton, BModal } from "bootstrap-vue";
import { mapState, mapMutations, mapGetters } from "vuex";
import LearnSpellModal from "./LearnSpellModal.vue";

export default {
  name: "LevelUpModal",
  props: {
    levelUpModal: {
      type: String,
      required: true,
    },
  },
  components: {
    BButton,
    BModal,
    LearnSpellModal,
  },
  computed: {
    ...mapState(["wizard"]),
    additionalSpells: {
      get() {
        return this.$store.state.additionalSpells;
      },
      set(value) {
        this.$store.commit("updateAdditionalSpells", value);
      },
    },
    schoolSpell1: {
      get() {
        return this.$store.state.schoolSpell1;
      },
      set(value) {
        this.$store.commit("updateSchoolSpell1", value);
      },
    },
    schoolSpell2: {
      get() {
        return this.$store.state.schoolSpell2;
      },
      set(value) {
        this.$store.commit("updateSchoolSpell2", value);
      },
    },
    schoolSpell3: {
      get() {
        return this.$store.state.schoolSpell3;
      },
      set(value) {
        this.$store.commit("updateSchoolSpell3", value);
      },
    },
    alignedSpell1: {
      get() {
        return this.$store.state.alignedSpell1;
      },
      set(value) {
        this.$store.commit("updateAlignedSpell1", value);
      },
    },
    alignedSpell2: {
      get() {
        return this.$store.state.alignedSpell2;
      },
      set(value) {
        this.$store.commit("updateAlignedSpell2", value);
      },
    },
    alignedSpell3: {
      get() {
        return this.$store.state.alignedSpell3;
      },
      set(value) {
        this.$store.commit("updateAlignedSpell3", value);
      },
    },
    neutralSpell1: {
      get() {
        return this.$store.state.neutralSpell1;
      },
      set(value) {
        this.$store.commit("updateNeutralSpell1", value);
      },
    },
    neutralSpell2: {
      get() {
        return this.$store.state.neutralSpell2;
      },
      set(value) {
        this.$store.commit("updateNeutralSpell2", value);
      },
    },
    gold: {
      get() {
        return this.$store.state.gold;
      },
      set(value) {
        this.$store.commit('updateGold', value);
      }
    }
  },
  methods: {
    ...mapMutations(["updateWizard"]),
    handleClose() {
      this.$bvModal.hide(this.levelUpModal);
    },
    openLearnSpellModal() {
      this.$bvModal.show("learnSpellModal");
    },
    updateGold(event) {
      if (event && event.target) {
        this.$store.commit("updateGold", Number(event.target.value));
      } else {
        console.error("Event or event target is undefined");
      }
    },
    updateWizardProperty(property, event) {
      const value = event.target.value;
      this.updateWizard({ property, value });
    },
  }
};
</script>
