<template>
  <div>
    <b-modal
      :id="baseModal"
      :title="'Wizard Base - Gold Available: ' + gold + 'gc'"
      size="xxl"
      scrollable
    >
      <div class="col-sm-12">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1"
              >Choose a Base (This will remove any existing resources)</span
            >
          </div>
          <select
            v-model="tempSelectedBase"
            class="form-control"
            @change="confirmChange"
          >
            <option v-for="base in bases" :key="base.location" :value="base">
              {{ base.location }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-sm-12" v-if="myBase">
        <h4>{{ myBase.location }}</h4>
        <p>{{ myBase.effects }}</p>
      </div>

      <div class="col-sm-12">
        <hr />
      </div>

      <div class="col-sm-12">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1"
              >Add a Resource</span
            >
          </div>
          <select
            v-model="tempSelectedResource"
            class="form-control"
            @change="confirmBuyResource"
          >
            <option
              v-for="resource in resources"
              :key="resource.resource"
              :value="resource"
            >
              {{ resource.resource }} {{ resource.cost }}gc
            </option>
          </select>
        </div>
      </div>

      <div class="col-sm-12">
        <div class="row">
          <div
            v-for="resource in myBaseResources"
            :key="resource.resource"
            class="col-sm-12 mb-3"
          >
            <div class="card parchment-card">
              <div class="card-body">
                <h5 class="card-title">{{ resource.resource }}</h5>
                <p class="card-text">{{ resource.effects }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="secondary" @click="handleClose">Close</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal } from "bootstrap-vue";
import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  name: "BaseModal",
  data() {
    return {
      tempSelectedBase: null,
      tempSelectedResource: null,
    };
  },
  created() {
    this.tempSelectedBase = this.selectedBase;
  },
  props: {
    baseModal: {
      type: String,
      required: true,
    },
    gold: {
      type: Number,
      required: true,
    },
  },
  components: {
    BButton,
    BModal,
  },
  computed: {
    ...mapState(["myBase"]),
    ...mapState(["myBaseResources"]),
    bases() {
      return this.$store.state.bases;
    },
    resources() {
      return this.$store.state.baseResources;
    },
    selectedBase() {
      return this.$store.state.myBase;
    },
  },
  methods: {
    handleClose() {
      this.$bvModal.hide(this.baseModal);
    },
    confirmBuyResource(event) {
      console.log(event);
      const gold = this.$store.state.gold;
      const updatedGold = gold - this.tempSelectedResource.cost;
      var message;
      var color;
      if (updatedGold >= 0) {
        if (
          confirm(
            "Are you sure you want to buy " +
              this.tempSelectedResource.resource +
              " for " +
              this.tempSelectedResource.cost +
              "gc?"
          )
        ) {
          message =
            this.tempSelectedResource.resource +
            " is now available at your base!";
          color = "success";
          this.$store.commit("buyResource", this.tempSelectedResource);
          this.$store.commit("updateGold", updatedGold);
        }
      } else {
        message = "You are too poor! Best go find some treasure!";
        color = "danger";
      }
      this.$emit("show-toast", message, color);
      this.tempSelectedResource = null;
    },
    confirmChange(event) {
      if (
        confirm("Any existing base resources will be removed. Are you sure?")
      ) {
        this.$store.commit("setMyBase", this.tempSelectedBase);
      } else {
        this.tempSelectedBase = this.selectedBase;
      }
    },
  },
};
</script>
