<template>
    <div class="toast-container">
      <div
        v-for="(toast, index) in toastMessages"
        :key="index"
        :class="['toast', 'show', `bg-${toast.color}`, 'text-light']"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        style="margin-bottom: 10px"
      >
        <div class="toast-header d-flex justify-content-between">
          <strong class="me-auto">Warband Manager</strong>
          <button type="button" class="ml-2 mb-1 close" @click="hideToast(index)" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="toast-body">
          {{ toast.text }}
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ToastContainer",
    props: {
      toastMessages: {
        type: Array,
        default: () => [],
      },
    },
    methods: {
      hideToast(index) {
        this.$emit("hide-toast", index);
      },
    },
  };
  </script>
  
  <style>
  .toast-container {
    position: fixed;
    top: 20px;
    /* right: 20px; */
    display: flex;
    flex-direction: column-reverse;
    z-index: 9999;
  }
  .toast {
    max-height: 100px; /* Adjust the height as desired */
    border-radius: 0.25rem;
  }
  </style>
  