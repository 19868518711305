import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    soldiers: [],
    bases: [],
    baseResources: [],
    myBase: {},
    myBaseResources: [],
    warband: [], // Placeholder for the warband data
    schoolSpell1: 'none',
    schoolSpell2: 'none',
    schoolSpell3: 'none',
    alignedSpell1: 'none',
    alignedSpell2: 'none',
    alignedSpell3: 'none',
    neutralSpell1: 'none',
    neutralSpell2: 'none',
    additionalSpells: [],
    gold: 400,
    wizard: {
      name: 'Give me a name!!!',
      move: 6,
      fight: +2,
      shoot: 0,
      armour: 10,
      will: +4,
      health: 14,
      currentHealth: 14,
      level: 0,
      xp: 0,
      items: '',
      notes: '',
    },
    hasApprentice: false,
    apprentice: {
      name: 'Give me a name!!!',
      move: 6,
      fight: 0,
      shoot: 0,
      armour: 10,
      will: +2,
      health: 12,
      currentHealth: 12,
      items: '',
      notes: '',
    },
    spells: [],
    schools: [],
    selectedSchool: null
  },
  mutations: {
    addSoldier(state, soldier) {
      soldier.currentHealth = soldier.health
      state.warband.push(soldier);
    },
    addMercenary(state, soldier) {
      soldier.currentHealth = soldier.health
      soldier.name = soldier.class
      soldier.notes = ''
      state.soldiers.push(soldier);
    },
    buyResource(state, resource) {
      state.myBaseResources.push(resource);
    },
    addSpell(state, spell) {
      state.spells.push(spell);
    },
    removeSoldier(state, payload) {

      const { index, refund } = payload;
      const soldier = state.warband[index];
      state.warband.splice(index, 1);
      console.log(refund);
      if (refund === true) {
        let soldierCost = soldier.cost;
        const myBaseResources = state.myBaseResources;
        console.log(myBaseResources);
        if (myBaseResources.some(resource => resource.resource === 'Carrier Pigeons')) {
          soldierCost = Math.max(0, soldierCost - 10);
        }
        state.gold = parseInt(state.gold, 10) + parseInt(soldierCost, 10);
      }
    },
    setGold(state, value) {
      state.gold = value;
    },
    resetWarband(state) {
      Object.assign(state, getDefaultState());
    },
    async resetCodex(state){
      state.soldiers = []
      state.schools = []
      state.spells = []
      try {
        const response = await fetch("/data/soldiers.json");
        const soldiers = await response.json();
        state.soldiers = soldiers;
      } catch (error) {
        state.soldiers = []; // Set an empty array in case of an error
      }
      try {
        const response = await fetch("/data/schools.json");
        const schools = await response.json();
        state.schools = schools;
      } catch (error) {
        console.error("Error fetching schools:", error);
        state.schools = []; // Set an empty array in case of an error
      }
      try {
        const response = await fetch("/data/spells.json");
        const spells = await response.json();
        state.spells = spells;
      } catch (error) {
        console.error("Error fetching spells:", error);
        state.spells = []; // Set an empty array in case of an error
      }
    },
    setSoldiers(state, soldiers) {
      state.soldiers = soldiers;
    },
    setBases(state, bases) {
      state.bases = bases;
    },
    setBaseResources(state, baseResources) {
      state.baseResources = baseResources;
    },
    setMyBase(state, base) {
      state.myBase = base;
      state.myBaseResources = [];
    },
    setSchools(state, schools) {
      state.schools = schools;
    },
    setSpells(state, spells) {
      state.spells = spells;
    },
    setSelectedSchool(state, selectedSchoolId) {
      state.selectedSchool = selectedSchoolId;
      state.schoolSpell1 = 'none'
      state.schoolSpell2 = 'none'
      state.schoolSpell3 = 'none'
      state.alignedSpell1 = 'none'
      state.alignedSpell2 = 'none'
      state.alignedSpell3 = 'none'
      state.neutralSpell1 = 'none'
      state.neutralSpell2 = 'none'
      state.additionalSpells = []
    },
    updateEquipment(state, payload) {
      const { soldierIndex, equipment } = payload;
      state.warband[soldierIndex].equipment = equipment;
    },
    updateGold(state, updatedGold) {
      state.gold = updatedGold;
    },
    updateSoldier(state, { index, property, value }) {
      const soldier = state.warband[index];
      Vue.set(soldier, property, value); // Use Vue.set to update the property of the soldier object
    },       
    updateWizard(state, { property, value }) {
      // Check if the property exists in the wizard object
      if (state.wizard.hasOwnProperty(property)) {
        state.wizard[property] = value;
      }
    },
    updateApprentice(state, { property, value }) {
      if (state.apprentice.hasOwnProperty(property)) {
        state.apprentice[property] = value;
      }
    },
    setSchoolSpell1(state, value) {
      console.log(value)
      const spell = state.spells.find((spell) => spell.name === value);
      state.schoolSpell1 = { ...spell };
    },  
    setSchoolSpell2(state, value) {
      const spell = state.spells.find((spell) => spell.name === value);
      state.schoolSpell2 = { ...spell };
    },
    setSchoolSpell3(state, value) {
      const spell = state.spells.find((spell) => spell.name === value);
      state.schoolSpell3 = { ...spell };
    },
    setAlignedSpell1(state, value) {
      var spell = state.spells.find((spell) => spell.name === value);
      if (spell) {
        state.alignedSpell1 = { ...spell };
        state.alignedSpell1.targetNumber = (spell.targetNumber || 0) + 2;
      } else {
        // Handle the case when no spell is found
        // You can choose to set a default value or take any other appropriate action
        state.alignedSpell1 = 'none';
      }
    },  
    setAlignedSpell2(state, value) {
      var spell = state.spells.find((spell) => spell.name === value);
      if (spell) {
        state.alignedSpell2 = { ...spell };
        state.alignedSpell2.targetNumber = (spell.targetNumber || 0) + 2;
      } else {
        // Handle the case when no spell is found
        // You can choose to set a default value or take any other appropriate action
        state.alignedSpell2 = 'none';
      }
    },  
    setAlignedSpell3(state, value) {
      var spell = state.spells.find((spell) => spell.name === value);
      if (spell) {
        state.alignedSpell3 = { ...spell };
        state.alignedSpell3.targetNumber = (spell.targetNumber || 0) + 2;
      } else {
        // Handle the case when no spell is found
        // You can choose to set a default value or take any other appropriate action
        state.alignedSpell3 = 'none';
      }
    },  
    setNeutralSpell1(state, value) {
      var spell = state.spells.find((spell) => spell.name === value);
      if (spell) {
        state.neutralSpell1 = { ...spell };
        state.neutralSpell1.targetNumber = (spell.targetNumber || 0) + 4;
      } else {
        // Handle the case when no spell is found
        // You can choose to set a default value or take any other appropriate action
        state.neutralSpell1 = 'none';
      }
    },
    setNeutralSpell2(state, value) {
      var spell = state.spells.find((spell) => spell.name === value);
      if (spell) {
        state.neutralSpell2 = { ...spell };
        state.neutralSpell2.targetNumber = (spell.targetNumber || 0) + 4;
      } else {
        // Handle the case when no spell is found
        // You can choose to set a default value or take any other appropriate action
        state.neutralSpell2 = 'none';
      }
    },
    updateHasApprentice(state, value) {
      state.hasApprentice = value;
      if(state.hasApprentice){
        state.gold = state.gold -100;
      }else{
        state.gold = state.gold +100;
      }
    },  
    setLoadedData(state, loadedData) {
      Object.assign(state, loadedData);
    },
    updateSchoolSpell1(state, value) {
      state.schoolSpell1 = value;
    },
    updateSchoolSpell2(state, value) {
      state.schoolSpell2 = value;
    },
    updateSchoolSpell3(state, value) {
      state.schoolSpell3 = value;
    },
    updateAlignedSpell1(state, value) {
      state.alignedSpell1 = value;
    },
    updateAlignedSpell2(state, value) {
      state.alignedSpell2 = value;
    },
    updateAlignedSpell3(state, value) {
      state.alignedSpell3 = value;
    },
    updateNeutralSpell1(state, value) {
      state.neutralSpell1 = value;
    },
    updateNeutralSpell2(state, value) {
      state.neutralSpell2 = value;
    },
    learnSpell(state, spell) {
      state.additionalSpells.push(spell);
    },
  },
  actions: {
    async getBases({ commit }) {
      try {
        const response = await fetch("/data/bases.json");
        const bases = await response.json();
        commit("setBases", bases);
      } catch (error) {
        commit("setBases", []); // Set an empty array in case of an error
      }
    },
    async getBaseResources({ commit }) {
      try {
        const response = await fetch("/data/baseresources.json");
        const baseResources = await response.json();
        commit("setBaseResources", baseResources);
      } catch (error) {
        commit("setBaseResources", []); // Set an empty array in case of an error
      }
    },
    async getSoldiers({ commit }) {
      try {
        const response = await fetch("/data/soldiers.json");
        const soldiers = await response.json();
        commit("setSoldiers", soldiers);
      } catch (error) {
        commit("setSoldiers", []); // Set an empty array in case of an error
      }
    },
    async getSchools({ commit }) {
      try {
        const response = await fetch("/data/schools.json");
        const schools = await response.json();
        commit("setSchools", schools);
      } catch (error) {
        console.error("Error fetching schools:", error);
        commit("setSchools", []); // Set an empty array in case of an error
      }
    },
    async getSpells({ commit }) {
      try {
        const response = await fetch("/data/spells.json");
        const spells = await response.json();
        commit("setSpells", spells);
      } catch (error) {
        console.error("Error fetching spells:", error);
        commit("setSpells", []); // Set an empty array in case of an error
      }
    },
    setSelectedSchool({ commit }, selectedSchoolId) {
      commit("setSelectedSchool", selectedSchoolId);
    },
    saveToJSON({ state }) {
      return JSON.stringify(state); // Return the serialized JSON data
    },
    loadFromJSON({ commit }, data) {
      commit('resetWarband'); // Reset the state to the default values
      commit('setLoadedData', data); // Update the state with the loaded data
    },
  },
  getters: {
    getWarband: (state) => state.warband,
    getSoldiers: (state) => state.soldiers,
    getGold: (state) => state.gold,
    selectedSchool: (state) => state.selectedSchool,    
    getSchools: (state) => state.schools,
    getSelectedSchool: (state) => {
      const selectedSchool = state.schools.find((school) => school.id === state.selectedSchool);
      return selectedSchool ? selectedSchool.name : "None";
    },
  },
  plugins: [createPersistedState()],
});

function getDefaultState() {
  return {
    myBase: {},
    myBaseResources: [],
    warband: [], // Placeholder for the warband data
    schoolSpell1: 'none',
    schoolSpell2: 'none',
    schoolSpell3: 'none',
    alignedSpell1: 'none',
    alignedSpell2: 'none',
    alignedSpell3: 'none',
    neutralSpell1: 'none',
    neutralSpell2: 'none',
    additionalSpells: [],
    gold: 400,
    wizard: {
      name: 'Give me a name!!!',
      move: 6,
      fight: +2,
      shoot: 0,
      armour: 10,
      will: +4,
      health: 14,
      currentHealth: 14,
      level: 0,
      xp: 0,
      items: '',
      notes: '',
    },
    hasApprentice: false,
    apprentice: {
      name: 'Give me a name!!!',
      move: 6,
      fight: 0,
      shoot: 0,
      armour: 10,
      will: +2,
      health: 12,
      currentHealth: 12,
      items: '',
      notes: '',
    },
    selectedSchool: null
  };
}

