<template>
  <div class="row">
    <div class="col-sm-6 mb-2 text-center text-sm-left">
      <h3>
        <span class="font-weight-bold">{{ apprentice.name }}</span
        >, the Apprentice
      </h3>
    </div>
    <div class="col-sm-6 text-center text-sm-right mb-2">
      <button
        @click="editApprentice"
        class="btn btn-success mr-2"
        v-if="editing"
      >
        Finished Editing
      </button>
      <button
        @click="editApprentice"
        class="btn btn-warning mr-2"
        v-if="!editing"
      >
        Edit apprentice
      </button>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" v-show="editing">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">Name</span>
        </div>
        <input
          type="text"
          class="form-control"
          v-model="apprentice.name"
          @input="updateApprenticeProperty('name', $event)"
        />
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3" v-show="editing"></div>

    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3" v-show="editing"></div>

    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">Move</span>
        </div>
        <input
          type="number"
          min="0"
          step="1"
          class="form-control text-right"
          :disabled="!editing"
          v-model="apprentice.move"
          @input="updateApprenticeProperty('move', $event)"
        />
      </div>
    </div>

    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">Fight</span>
        </div>
        <input
          type="number"
          min="0"
          step="1"
          class="form-control text-right"
          :disabled="!editing"
          v-model="apprentice.fight"
          @input="updateApprenticeProperty('fight', $event)"
        />
      </div>
    </div>

    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">Shoot</span>
        </div>
        <input
          type="number"
          min="0"
          step="1"
          class="form-control text-right"
          :disabled="!editing"
          v-model="apprentice.shoot"
          @input="updateApprenticeProperty('shoot', $event)"
        />
      </div>
    </div>

    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">Armour</span>
        </div>
        <input
          type="number"
          min="0"
          step="1"
          class="form-control text-right"
          :disabled="!editing"
          v-model="apprentice.armour"
          @input="updateApprenticeProperty('armour', $event)"
        />
      </div>
    </div>

    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">Will</span>
        </div>
        <input
          type="number"
          min="0"
          step="1"
          class="form-control text-right"
          :disabled="!editing"
          v-model="apprentice.will"
          @input="updateApprenticeProperty('will', $event)"
        />
      </div>
    </div>

    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">Max HP</span>
        </div>
        <input
          type="number"
          min="0"
          step="1"
          class="form-control text-right"
          :disabled="!editing"
          v-model="apprentice.health"
          @input="updateApprenticeProperty('health', $event)"
        />
      </div>
    </div>

    <div class="col-xs-6 col-sm-6 col-md-5 col-lg-3 mb-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">HP</span>
          <button class="btn btn-health" type="button" @click="decrementHealth">
            <b-icon icon="chevron-compact-down"></b-icon>
          </button>
        </div>
        <input
          type="number"
          min="0"
          step="1"
          class="form-control text-right"
          v-model="apprentice.currentHealth"
        />
        <div class="input-group-append">
          <button class="btn btn-health" type="button" @click="incrementHealth">
            <b-icon icon="chevron-compact-up"></b-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-md-7 col-lg-9 mb-3"></div>

    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
      <div class="form-group">
        <label for="apprenticeNotes"><h5>Notes</h5></label>
        <textarea
          class="form-control"
          rows="4"
          :disabled="!editing"
          v-model="apprentice.notes"
          @input="updateApprenticeProperty('notes', $event)"
        ></textarea>
      </div>
    </div>

    <!-- For 'apprentice Items' -->
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
      <div class="form-group">
        <label for="apprenticeItems"><h5>Items</h5></label>
        <textarea
          class="form-control"
          rows="4"
          :disabled="!editing"
          v-model="apprentice.items"
          @input="updateApprenticeProperty('items', $event)"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      editing: false,
    };
  },
  computed: {
    ...mapState(["apprentice"]),
  },
  methods: {
    ...mapMutations(["updateApprentice"]),
    decrementHealth() {
      if (this.apprentice.currentHealth > 0) {
        this.apprentice.currentHealth--;
      }
    },
    incrementHealth() {
      this.apprentice.currentHealth++;
    },
    updateApprenticeProperty(property, event) {
      const value = event.target.value;
      this.updateApprentice({ property, value });
    },
    editApprentice() {
      this.editing = !this.editing;
    },
  },
  watch: {
    'apprentice.currentHealth': function (newVal, oldVal) {
      let event = { 
        target: { 
          value: newVal, 
          name: 'currentHealth' 
        } 
      };
      this.updateApprenticeProperty('currentHealth', event);
    },
  }
};
</script>
