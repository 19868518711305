<template>
  <div class="row">
    <div class="col-sm-12 d-flex justify-content-between mb-3">
      <h3>Mercenaries {{ warband.length }} of 8</h3>
      <div>
        <b-button class="btn btn-warning mr-1" @click="startTurn"
          >Start Turn</b-button
        >
        <b-button v-b-modal.selectSoldierModal class="btn btn-success"
          >Hire</b-button
        >
      </div>
    </div>

    <div class="col-sm-12">
      <div class="row" v-for="(soldier, index) in warband" :key="soldier.id">
        <div class="col-xs-12 col-md-6 col-lg-6 mb-3">
          <h3>
            <span class="font-weight-bold">{{ soldier.name }}</span
            >, {{ soldier.class }} ({{ soldier.type }})
          </h3>
        </div>

        <div class="col-xs-6 col-sm-6 col-md-4 col-lg-1 mb-3">
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input"
              :id="'switch_' + index"
              :checked="soldierSwitchState[index]"
            />
            <label class="custom-control-label" :for="'switch_' + index"
              ></label
            >
          </div>
        </div>

        <div class="col-sm-6 col-md-2 col-sm-lg-2">
          <button
            @click="editEnd"
            class="btn btn-success btn-block mr-2"
            v-if="editing == $store.state.warband.indexOf(soldier)"
          >
            Finished Editing
          </button>
          <button
            @click="editStart(soldier)"
            class="btn btn-warning btn-block mr-2"
            v-if="editing != $store.state.warband.indexOf(soldier)"
          >
            Edit
          </button>
        </div>

        <div class="col-xs-6 col-sm-6 col-md-4 col-lg-3 mb-3">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">HP</span>
              <button
                class="btn btn-health"
                type="button"
                @click="decrementHealth(soldier)"
              >
                <b-icon icon="chevron-compact-down"></b-icon>
              </button>
            </div>
            <input
              type="number"
              min="0"
              step="1"
              class="form-control text-right"
              v-model="soldier.currentHealth"
            />
            <div class="input-group-append">
              <button
                class="btn btn-health"
                type="button"
                @click="incrementHealth(soldier)"
              >
                <b-icon icon="chevron-compact-up"></b-icon>
              </button>
            </div>
          </div>
        </div>

        <div
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6"
          v-show="editing == $store.state.warband.indexOf(soldier)"
        >
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">Name</span>
            </div>
            <input
              type="text"
              class="form-control"
              v-model="soldier.name"
              @input="updateSoldierProperty('name', $event, soldier)"
            />
          </div>
        </div>

        <div
          class="col-xs-12 col-sm-6 col-md-3 col-lg-3"
          v-show="editing == $store.state.warband.indexOf(soldier)"
        ></div>

        <div
          class="col-xs-12 col-sm-6 col-md-3 col-lg-3"
          v-show="editing == $store.state.warband.indexOf(soldier)"
        ></div>

        <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">Move</span>
            </div>
            <input
              type="number"
              min="0"
              step="1"
              class="form-control text-right"
              :disabled="editing != $store.state.warband.indexOf(soldier)"
              v-model="soldier.move"
              @input="updateSoldierProperty('move', $event, soldier)"
            />
          </div>
        </div>

        <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">Fight</span>
            </div>
            <input
              type="number"
              min="0"
              step="1"
              class="form-control text-right"
              :disabled="editing != $store.state.warband.indexOf(soldier)"
              v-model="soldier.fight"
              @input="updateSoldierProperty('fight', $event, soldier)"
            />
          </div>
        </div>

        <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">Shoot</span>
            </div>
            <input
              type="number"
              min="0"
              step="1"
              class="form-control text-right"
              :disabled="editing != $store.state.warband.indexOf(soldier)"
              v-model="soldier.shoot"
              @input="updateSoldierProperty('shoot', $event, soldier)"
            />
          </div>
        </div>

        <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">Armour</span>
            </div>
            <input
              type="number"
              min="0"
              step="1"
              class="form-control text-right"
              :disabled="editing != $store.state.warband.indexOf(soldier)"
              v-model="soldier.armour"
              @input="updateSoldierProperty('armour', $event, soldier)"
            />
          </div>
        </div>

        <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">Will</span>
            </div>
            <input
              type="number"
              min="0"
              step="1"
              class="form-control text-right"
              :disabled="editing != $store.state.warband.indexOf(soldier)"
              v-model="soldier.will"
              @input="updateSoldierProperty('will', $event, soldier)"
            />
          </div>
        </div>

        <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">Max HP</span>
            </div>
            <input
              type="number"
              min="0"
              step="1"
              class="form-control text-right"
              :disabled="editing != $store.state.warband.indexOf(soldier)"
              v-model="soldier.health"
              @input="updateSoldierProperty('health', $event, soldier)"
            />
          </div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">Notes</span>
            </div>
            <input
              type="text"
              class="form-control"
              :disabled="editing != $store.state.warband.indexOf(soldier)"
              v-model="soldier.notes"
              @input="updateSoldierProperty('notes', $event, soldier)"
            />
          </div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">Gear</span>
            </div>
            <input
              type="text"
              class="form-control"
              :disabled="editing != $store.state.warband.indexOf(soldier)"
              v-model="soldier.gear"
              @input="updateSoldierProperty('gear', $event, soldier)"
            />
          </div>
        </div>

        <div class="col-sm-6 col-md-2 col-sm-lg-2">
          <b-button
            block
            class="btn btn-danger"
            @click="deleteSoldier(soldier)"
          >
            Fire
          </b-button>
        </div>

        <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2 mb-3">
          <b-button
            block
            class="btn btn-bury"
            @click="deleteSoldier(soldier, false)"
          >
            Bury
          </b-button>
        </div>

        <div class="col-sm-12">
          <hr />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      editing: -1,
      soldierSwitches: {},
    };
  },
  computed: {
    ...mapState(["warband"]),
    soldierSwitchState() {
      return this.warband.map((soldier) => {
        return this.soldierSwitches[soldier.id] || false;
      });
    },
  },
  methods: {
    decrementHealth(soldier) {
      if (soldier.currentHealth > 0) {
        soldier.currentHealth--;
      }
    },
    incrementHealth(soldier) {
      soldier.currentHealth++;
    },
    startTurn() {
      this.warband.forEach((soldier) => {
        soldier.activated = false;
      });

      // Reset the switch state for all soldiers to false
      this.soldierSwitches = {};

      // Get all the switches and set their checked property to false
      const switches = document.querySelectorAll(".custom-control-input");
      switches.forEach((sw) => {
        sw.checked = false;
      });
    },
    toggleSwitch(index) {
      // This method is called when a switch is clicked
      // You can add any logic here that you want to perform when a switch is clicked.
      // For now, we are just logging the click event.
      console.log("Switch clicked for soldier at index", index);

      // Update the switch state for the clicked soldier
      const soldier = this.warband[index];
      this.soldierSwitches[soldier.id] = !this.soldierSwitches[soldier.id];
    },
    deleteSoldier(soldier, refund) {
      var theName = soldier.name;
      if (theName == "") {
        theName = soldier.class;
      }
      if (refund == false) {
        if (confirm(theName + " will be laid to rest. Are you sure?")) {
          const index = this.$store.state.warband.indexOf(soldier);
          this.$store.commit("removeSoldier", { index, refund: false });
        }
      } else if (confirm(theName + " will be fired. Are you sure?")) {
        const index = this.$store.state.warband.indexOf(soldier);
        if (index > -1) {
          if (soldier.cost > 0) {
            if (confirm("Refund Gold?")) {
              this.$store.commit("removeSoldier", { index, refund: true });
            } else {
              this.$store.commit("removeSoldier", { index, refund: false });
            }
          } else {
            this.$store.commit("removeSoldier", { index, refund: false });
          }
        }
      }
    },
    updateSoldierProperty(property, event, soldier) {
      console.log("updateSoldierProperty", property, event, soldier);
      const value = event.target.value;
      const index = this.warband.indexOf(soldier);
      if (index > -1) {
        this.$store.commit("updateSoldier", { index, property, value });
      }
    },
    ...mapMutations(["removeSoldier"]),
    editStart(soldier) {
      const index = this.$store.state.warband.indexOf(soldier);
      console.log(index);
      this.editing = index;
    },
    editEnd() {
      this.editing = -1;
    },
  },
  watch: {
    warband: {
      handler: function (warband) {
        warband.forEach((soldier, index) => {
          this.$watch(
            () => soldier.currentHealth,
            (newVal) => {
              this.updateSoldierProperty('currentHealth', { target: { value: newVal } }, soldier);
            }
          );
        });
      },
      deep: true,
      immediate: true,
    },
  }
};
</script>
