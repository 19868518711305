<template>
    <div>
        <b-modal
            :id="addMercenaryModal"
            :title="'Add Mercenary'"
            size="xxl"
        >
        <div class="row">

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">Class</span>
                    </div>
                    <input type="text" class="form-control" v-model="soldierClass">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">Type</span>
                    </div>
                    <select class="form-control" v-model="type">
                        <option value="Standard">Standard</option>
                        <option value="Specialist">Specialist</option>
                    </select>
                </div>
            </div>

            <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">Move</span>
                    </div>
                    <input type="number" min="0" step="1" class="form-control text-right" v-model.number="move">
                </div>
            </div>

            <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">Fight</span>
                    </div>
                    <input type="number" min="0" step="1" class="form-control text-right" v-model.number="fight">
                </div>
            </div>

            <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">Shoot</span>
                    </div>
                    <input type="number" min="0" step="1" class="form-control text-right" v-model.number="shoot">
                </div>
            </div>

            <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">Armour</span>
                    </div>
                    <input type="number" min="0" step="1" class="form-control text-right" v-model.number="armour">
                </div>
            </div>

            <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">Will</span>
                    </div>
                    <input type="number" min="0" step="1" class="form-control text-right" v-model.number="will">
                </div>
            </div>

            <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">Max HP</span>
                    </div>
                    <input type="number" min="0" step="1" class="form-control text-right" v-model.number="health">
                </div>
            </div>

            <div class="col-xs-12 col-sm-3">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">Cost</span>
                    </div>
                    <input type="number" step="1" min="0" class="form-control" v-model.number="cost">
                </div>
            </div>

            <div class="col-xs-12 col-sm-9">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">Gear</span>
                    </div>
                    <input type="text" class="form-control" v-model="gear">
                </div>
            </div>
        
        </div>       
        
        <template #modal-footer>
          <div class="d-flex justify-content-end w-100">            
            <b-button variant="success" @click="addMercenary">Save</b-button>            
            <b-button variant="secondary" class="ml-2" @click="handleClose">Close</b-button>
          </div>
        </template>
      </b-modal>
    </div>
  </template>
    
    <script>
    import { BButton, BModal } from "bootstrap-vue";
    
    export default {
      name: "AddMercenaryModal",
      data() {
        return {        
            soldierClass: '',
            type: 'Standard',
            move: 0,
            fight: 0,
            shoot: 0,
            armour: 0,
            will: 0,
            health: 0,
            cost: 0,
            gear: ''
        };
      },
      props: {
        addMercenaryModal: {
          type: String,
          required: true
        },
      },
      components: {
        BButton,
        BModal,
      },
      methods: { 
        handleClose() {
          this.$bvModal.hide(this.addMercenaryModal);
        },
        addMercenary() {
            // Create a new soldier object using the entered data
            const newSoldier = {
                class: this.soldierClass,
                type: this.type,
                move: this.move,
                fight: this.fight,
                shoot: this.shoot,
                armour: this.armour,
                will: this.will,
                health: this.health,
                cost: this.cost,
                gear: this.gear,
            };

            // Add the new soldier to the store's 'soldiers' array
            this.$store.commit("addMercenary", newSoldier);

            // Clear the form data after saving the soldier
            this.soldierClass = "";
            this.type = "Standard";
            this.move = 0;
            this.fight = 0;
            this.shoot = 0;
            this.armour = 0;
            this.will = 0;
            this.health = 0;
            this.cost = 0;
            this.gear = "";

            // Close the modal after saving
            this.$bvModal.hide(this.addMercenaryModal);
            alert("New mercenary type added to Codex.")

        },
      }
    };
    </script>
    