<template>
  <div class="row">
    <div class="col-sm-6 mb-2 text-center text-sm-left">
      <h3>
        <span class="font-weight-bold">{{ wizard.name }}</span
        >, Level {{ wizard.level }} {{ getSelectedSchool }} Wizard
      </h3>
    </div>
    <div class="col-sm-6 text-center text-sm-right mb-2">
      <button @click="editWizard" class="btn btn-success mr-2" v-if="editing">
        Finished Editing
      </button>
      <button @click="editWizard" class="btn btn-warning mr-2" v-if="!editing">
        Edit Wizard
      </button>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" v-show="editing">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">Name</span>
        </div>
        <input
          type="text"
          class="form-control"
          v-model="wizard.name"
          @input="updateWizardProperty('name', $event)"
        />
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-2 col-lg-2" v-show="editing">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">Experience</span>
        </div>
        <input
          type="number"
          min="0"
          step="1"
          class="form-control text-right"
          v-model="wizard.xp"
          @input="updateWizardProperty('xp', $event)"
        />
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-2 col-lg-2" v-show="editing">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">Level</span>
        </div>
        <input
          type="number"
          min="0"
          step="1"
          class="form-control text-right"
          v-model="wizard.level"
          @input="updateWizardProperty('level', $event)"
        />
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-2 col-lg-2" v-show="editing">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">Gold</span>
        </div>
        <input
          type="number"
          min="0"
          step="1"
          class="form-control text-right"
          v-model="storeGold"
        />
      </div>
    </div>

    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">Move</span>
        </div>
        <input
          type="number"
          min="0"
          step="1"
          class="form-control text-right"
          :disabled="!editing"
          v-model="wizard.move"
          @input="updateWizardProperty('move', $event)"
        />
      </div>
    </div>

    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">Fight</span>
        </div>
        <input
          type="number"
          min="0"
          step="1"
          class="form-control text-right"
          :disabled="!editing"
          v-model="wizard.fight"
          @input="updateWizardProperty('fight', $event)"
        />
      </div>
    </div>

    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">Shoot</span>
        </div>
        <input
          type="number"
          min="0"
          step="1"
          class="form-control text-right"
          :disabled="!editing"
          v-model="wizard.shoot"
          @input="updateWizardProperty('shoot', $event)"
        />
      </div>
    </div>

    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">Armour</span>
        </div>
        <input
          type="number"
          min="0"
          step="1"
          class="form-control text-right"
          :disabled="!editing"
          v-model="wizard.armour"
          @input="updateWizardProperty('armour', $event)"
        />
      </div>
    </div>

    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">Will</span>
        </div>
        <input
          type="number"
          min="0"
          step="1"
          class="form-control text-right"
          :disabled="!editing"
          v-model="wizard.will"
          @input="updateWizardProperty('will', $event)"
        />
      </div>
    </div>

    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">Max HP</span>
        </div>
        <input
          type="number"
          min="0"
          step="1"
          class="form-control text-right"
          :disabled="!editing"
          v-model="wizard.health"
          @input="updateWizardProperty('health', $event)"
        />
      </div>
    </div>

    <div class="col-xs-6 col-sm-6 col-md-5 col-lg-3 mb-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">HP</span>
          <button
            class="btn btn-health"
            type="button"
            @click="decrementHealth"
          >
          <b-icon icon="chevron-compact-down"></b-icon>
          </button>
        </div>
        <input
          type="number"
          min="0"
          step="1"
          class="form-control text-right"
          v-model="wizard.currentHealth"
        />
        <div class="input-group-append">
          <button
            class="btn btn-health"
            type="button"
            @click="incrementHealth"
          >
          <b-icon icon="chevron-compact-up"></b-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-md-7 col-lg-9 mb-3">
      <select v-model="setHasApprentice" class="form-control">
        <option :value="true">
          I wish to impart my knowledge to a worthy assistant!
        </option>
        <option :value="false">
          I am too busy and important to take on an apprentice!
        </option>
      </select>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
      <div class="form-group">
        <label for="wizardNotes"><h5>Notes</h5></label>
        <textarea
          class="form-control"
          rows="4"
          :disabled="!editing"
          v-model="wizard.notes"
          @input="updateWizardProperty('notes', $event)"
        ></textarea>
      </div>
    </div>

    <!-- For 'Wizard Items' -->
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
      <div class="form-group">
        <label for="wizardItems"><h5>Items</h5></label>
        <textarea
          class="form-control"
          rows="4"
          :disabled="!editing"
          v-model="wizard.items"
          @input="updateWizardProperty('items', $event)"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  data() {
    return {
      editing: false,
    };
  },
  computed: {
    ...mapState(["wizard"]),
    ...mapGetters(["getSelectedSchool"]),
    ...mapState(["hasApprentice"]),
    storeGold: {
      get() {
        return this.$store.state.gold;
      },
      set(value) {
        this.$store.commit("setGold", value);
      },
    },
    setHasApprentice: {
      get() {
        return this.hasApprentice;
      },
      set(value) {
        if (this.$store.state.hasApprentice) {
          this.$store.commit("updateHasApprentice", value);
        } else {
          if (this.$store.state.gold >= 100) {
            this.$store.commit("updateHasApprentice", value);
          } else {
            this.showCustomToast(
              "You can't afford to train someone right now!",
              "danger"
            );
          }
        }
      },
    },
  },
  methods: {
    ...mapMutations(["updateWizard"]),
    editWizard() {
      this.editing = !this.editing;
    },
    decrementHealth() {
      if (this.wizard.currentHealth > 0) {
        this.wizard.currentHealth--;
      }
    },
    incrementHealth() {
      this.wizard.currentHealth++;
    },
    updateWizardProperty(property, event) {
      const value = event.target.value;
      this.updateWizard({ property, value });
    },
  },
  watch: {
    'wizard.currentHealth': function (newVal, oldVal) {
      let event = { 
        target: { 
          value: newVal, 
          name: 'currentHealth' 
        } 
      };
      this.updateWizardProperty('currentHealth', event);
    },
  }
};
</script>
