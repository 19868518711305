<template>
  <div>
    <b-modal :id="settingsModal" :title="'Settings'">
      <div class="row">
        <div class="col-sm-12 text-center">
          <h4>Manage Warband</h4>
        </div>
        <div class="col-6">
          <b-button
            class="btn btn-block btn-success mb-3"
            @click="saveWarbandDataToFile"
            >Export Warband</b-button
          >
        </div>
        <div class="col-6">
          <b-button
            class="btn btn-block btn-warning"
            @click="importWarbandDataFromFile"
            >Import Warband</b-button
          >
        </div>
        <div class="col-sm-12">
          <button @click="resetWarband" class="btn btn-block btn-danger mb-3">
            Reset Warband
          </button>
        </div>

        <div class="col-sm-12">
          <hr />
        </div>

        <div class="col-sm-12 text-center">
          <h4>Manage Codex</h4>
        </div>
        <div class="col-6">
          <b-button
            class="btn btn-block btn-primary mb-3"
            @click="openAddMercenaryModal"
            >Add Mercenary</b-button
          >
        </div>
        <div class="col-6">
          <b-button class="btn btn-block btn-primary" @click="openAddSpellModal"
            >Add Spell</b-button
          >
        </div>
        <div class="col-6">
          <b-button
            class="btn btn-block btn-success mb-3"
            @click="saveAppDataToFile"
            >Export Codex</b-button
          >
        </div>
        <div class="col-6">
          <b-button
            class="btn btn-block btn-warning"
            @click="importAppDataFromFile"
            >Import Codex</b-button
          >
        </div>
        <div class="col-sm-12">
          <button @click="resetCodex" class="btn btn-block btn-danger mb-3">
            Reset Codex
          </button>
        </div>

        <div class="col-sm-12">
          <hr />
        </div>

        <!-- <div class="col-sm-12">      
          <button @click="generatePdf" class="btn btn-block btn-success mb-3">Generate PDF</button>
        </div>   -->

        <div class="col-sm-12">
          <a
            href="https://www.buymeacoffee.com/jur451c"
            target="_blank"
            class="btn btn-block btn-primary mb-3 text-light"
            >Buy me a coffee!</a
          >
        </div>
      </div>
      <template #modal-footer>
        <div class="d-flex justify-content-between w-100">
          <span>V1.4.4</span>
          <b-button variant="secondary" @click="handleClose">Close</b-button>
        </div>
      </template>
    </b-modal>

    <add-mercenary-modal :addMercenaryModal="'addMercenaryModal'" />

    <add-spell-modal :addSpellModal="'addSpellModal'" />
  </div>
</template>

<script>
import { BButton, BModal } from "bootstrap-vue";
import AddMercenaryModal from "./AddMercenaryModal.vue";
import AddSpellModal from "./AddSpellModal.vue";
import { jsPDF } from "jspdf";

export default {
  name: "SettingsModal",
  props: {
    settingsModal: {
      type: String,
      required: true,
    },
  },
  components: {
    BButton,
    BModal,
    AddMercenaryModal,
    AddSpellModal,
  },
  methods: {
    handleClose() {
      this.$bvModal.hide(this.settingsModal);
    },
    generatePdf() {
      const doc = new jsPDF();
      doc.text("Hello world!", 10, 10);
      doc.save("warband.pdf");
    },
    async saveWarbandDataToFile() {
      const {
        warband,
        myBase,
        myBaseResources,
        schoolSpell1,
        schoolSpell2,
        schoolSpell3,
        alignedSpell1,
        alignedSpell2,
        alignedSpell3,
        neutralSpell1,
        neutralSpell2,
        additionalSpells,
        gold,
        wizard,
        hasApprentice,
        apprentice,
        selectedSchool,
      } = this.$store.state;

      // Create a new object with only the specific data to export
      const exportData = {
        warband,
        myBase,
        myBaseResources,
        schoolSpell1,
        schoolSpell2,
        schoolSpell3,
        alignedSpell1,
        alignedSpell2,
        alignedSpell3,
        neutralSpell1,
        neutralSpell2,
        additionalSpells,
        gold,
        wizard,
        hasApprentice,
        apprentice,
        selectedSchool,
      };

      const jsonData = JSON.stringify(exportData);
      const blob = new Blob([jsonData], { type: "application/octet-stream" });

      // Create an anchor element and attach the blob data to it
      const a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.target = "_blank"; // Open in a new tab/window
      a.download = "warbandData.warband"; // Default name for the file

      // Trigger the "Save As" dialog
      const clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      a.dispatchEvent(clickEvent);

      // Clean up by revoking the URL object after the download is initiated
      URL.revokeObjectURL(a.href);
    },
    async importWarbandDataFromFile() {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept = ".warband";

      // Add an event listener to the file input to handle the loaded file
      fileInput.addEventListener("change", async (event) => {
        const file = event.target.files[0];

        if (!file) {
          return;
        }

        try {
          const jsonData = await this.readFileAsync(file);
          const loadedData = JSON.parse(jsonData);

          // Extract the specific data from the loaded object
          const {
            warband,
            myBase,
            myBaseResources,
            schoolSpell1,
            schoolSpell2,
            schoolSpell3,
            alignedSpell1,
            alignedSpell2,
            alignedSpell3,
            neutralSpell1,
            neutralSpell2,
            additionalSpells,
            gold,
            wizard,
            hasApprentice,
            apprentice,
            selectedSchool,
          } = loadedData;

          // Update the store with the loaded data
          this.$store.commit("resetWarband"); // Reset the state to the default values
          this.$store.commit("setLoadedData", {
            warband,
            myBase,
            myBaseResources,
            schoolSpell1,
            schoolSpell2,
            schoolSpell3,
            alignedSpell1,
            alignedSpell2,
            alignedSpell3,
            neutralSpell1,
            neutralSpell2,
            additionalSpells,
            gold,
            wizard,
            hasApprentice,
            apprentice,
            selectedSchool,
          });

          // Show an alert to indicate the import is complete
          alert("Data import is complete.");
        } catch (error) {
          console.error("Error reading file:", error);
        }
      });

      // Trigger the file input dialog
      fileInput.click();
    },
    async saveAppDataToFile() {
      const { soldiers, spells, schools } = this.$store.state;

      // Create a new object with only the specific data to export
      const exportData = {
        soldiers,
        spells,
        schools,
      };

      const jsonData = JSON.stringify(exportData);
      const blob = new Blob([jsonData], { type: "application/octet-stream" });

      // Create an anchor element and attach the blob data to it
      const a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.target = "_blank"; // Open in a new tab/window
      a.download = "custom.codex"; // Default name for the file

      // Trigger the "Save As" dialog
      const clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      a.dispatchEvent(clickEvent);

      // Clean up by revoking the URL object after the download is initiated
      URL.revokeObjectURL(a.href);
    },
    async importAppDataFromFile() {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept = ".codex";

      // Add an event listener to the file input to handle the loaded file
      fileInput.addEventListener("change", async (event) => {
        const file = event.target.files[0];

        if (!file) {
          return;
        }

        try {
          const jsonData = await this.readFileAsync(file);
          const loadedData = JSON.parse(jsonData);

          // Extract the specific data from the loaded object
          const { soldiers, spells, schools } = loadedData;

          // Update the store with the loaded data
          this.$store.commit("setLoadedData", {
            soldiers,
            spells,
            schools,
          });

          // Show an alert to indicate the import is complete
          alert("Data import is complete.");
        } catch (error) {
          console.error("Error reading file:", error);
        }
      });

      // Trigger the file input dialog
      fileInput.click();
    },
    resetCodex() {
      if (
        confirm(
          "You Codex will be reset, any custom soldiers or spells will be lost. This cannot be undone! Are you sure?"
        )
      ) {
        this.$store.commit("resetCodex");
        alert("Reset complete.");
      }
    },
    resetWarband() {
      if (
        confirm(
          "Your warband will be reset. This cannot be undone! Are you sure?"
        )
      ) {
        this.$store.commit("resetWarband");
        alert("Reset complete.");
      }
    },
    readFileAsync(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => resolve(event.target.result);
        reader.onerror = (error) => reject(error);
        reader.readAsText(file);
      });
    },
    openAddMercenaryModal() {
      this.$bvModal.show("addMercenaryModal");
    },
    openAddSpellModal() {
      this.$bvModal.show("addSpellModal");
    },
  },
};
</script>
