import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import store from "./store";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.prototype.$ = $;

Vue.config.productionTip = false;

new Vue({
  store,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
